import React, { useState } from "react";

const TableOfContents = (props) => {
  const [tableOpen, setTableOpen] = useState(true);
  return (
    <div className="border-primary border rounded-md p-4 mb-4 md:mb-8 mx-4 md:mx-16 lg:mx-80 lg:text-lg">
      <div className="flex items-center pb-2">
        <h2 className="font-poppins font-semibold text-xl lg:text-2xl">
          Table of Contents
        </h2>
        <button
          className="ml-auto text-primary hover:text-secondary"
          onClick={() => setTableOpen(!tableOpen)}
        >{`[${tableOpen ? "Hide" : "Show"}]`}</button>
      </div>
      <div
        className={`tableOfContents ${
          tableOpen
            ? "max-h-screen duration-1000"
            : "max-h-0 ease-menu duration-700"
        } transition-max-height overflow-hidden`}
        dangerouslySetInnerHTML={{ __html: props.content }}
      ></div>
    </div>
  );
};

export default TableOfContents;
