import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import TableOfContents from "../components/TableOfContents";

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  description,
  mainTag,
  title,
  author,
  date,
  contentComponent,
  disclaimer,
  featuredimage,
  helmet,
  relatedPosts,
  previousPost,
  nextPost,
  tableOfContents,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="section">
      {helmet || ""}
      <div className="px-4 md:px-8 lg:px-20">
        <h1 className="font-poppins font-semibold text-2xl md:text-3xl">
          {title}
        </h1>
        <p className="font-poppins italic pt-1 pb-6">{description}</p>
        <div>
          <div className="lg:px-20">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `featured image thumbnail for post ${title}`,
                width: featuredimage.childImageSharp.gatsbyImageData.width,
                height: featuredimage.childImageSharp.gatsbyImageData.height,
              }}
            />
          </div>
          <div className="flex gap-2 font-poppins text-sm md:text-lg py-1 lg:px-20">
            <p>
              By:{" "}
              <Link to="/about" className="text-primary hover:text-secondary">
                {author}
              </Link>
            </p>
            <p> | </p>
            <p>{date}</p>
            <p> | </p>
            <p>
              <Link
                className="text-primary hover:text-secondary"
                to={`/tags/${kebabCase(mainTag)}`}
              >
                {mainTag}
              </Link>
            </p>
          </div>
        </div>
        <div className="s9-widget-wrapper lg:px-20"></div>
      </div>
      {disclaimer && (
        <p className="font-poppins italic text-sm md:text-base px-4 md:px-16 lg:px-80 py-4 md:py:8">
          Disclaimer: Some of the links provided in this article are affiliate
          links and I receive a commission when purchase an item after clicking
          on the link
        </p>
      )}

      <TableOfContents content={tableOfContents} />
      <PostContent content={content} className={`markdown`} />
      <div className="flex gap-4 px-4 md:px-16 lg:px-80 py-4 md:py-8 bg-primary">
        {previousPost && (
          <div className="flex-initial basis-1/2 lg:text-lg flex flex-col">
            <Link
              to={previousPost.fields.slug}
              className="text-white hover:text-secondary"
            >
              <h3>
                <FontAwesomeIcon icon={faArrowLeft} /> Previous Post
              </h3>
              <h2>{previousPost.frontmatter.title}</h2>
            </Link>
            <div className="w-3/4 lg:w-1/2 float-left mt-auto">
              <PreviewCompatibleImage
                imageInfo={{
                  image: previousPost.frontmatter.featuredimage,
                  alt: `featured image thumbnail for post ${previousPost.frontmatter.title}`,
                  width:
                    previousPost.frontmatter.featuredimage.childImageSharp
                      .gatsbyImageData.width,
                  height:
                    previousPost.frontmatter.featuredimage.childImageSharp
                      .gatsbyImageData.height,
                }}
              />
            </div>
          </div>
        )}
        {nextPost && (
          <div className="flex-initial basis-1/2 ml-auto text-right lg:text-lg flex flex-col items-end">
            <Link
              to={nextPost.fields.slug}
              className="text-white hover:text-secondary"
            >
              <h3>
                Next Post <FontAwesomeIcon icon={faArrowRight} />
              </h3>
              <h2>{nextPost.frontmatter.title}</h2>
            </Link>
            <div className="w-3/4 lg:w-1/2 mt-auto">
              <PreviewCompatibleImage
                imageInfo={{
                  image: nextPost.frontmatter.featuredimage,
                  alt: `featured image thumbnail for post ${nextPost.frontmatter.title}`,
                  width:
                    nextPost.frontmatter.featuredimage.childImageSharp
                      .gatsbyImageData.width,
                  height:
                    nextPost.frontmatter.featuredimage.childImageSharp
                      .gatsbyImageData.height,
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="px-4 md:px-8 lg:px-20 py-4 md:py-8">
        <h3 className="font-poppins font-semibold text-xl lg:text-2xl pb-2 md:pb-4">
          Related Posts
        </h3>
        <div className="flex flex-wrap gap-4 justify-around">
          {relatedPosts &&
            relatedPosts.map(({ node: post }) => {
              return (
                <div
                  title="Recent Post"
                  key={post.frontmatter.title}
                  className={`flex-col pb-8 md:pb-20 flex items-center lg:flex-1`}
                >
                  <div className="">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.featuredimage,
                        alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        width:
                          post.frontmatter.featuredimage.childImageSharp
                            .gatsbyImageData.width,
                        height:
                          post.frontmatter.featuredimage.childImageSharp
                            .gatsbyImageData.height,
                      }}
                    />
                  </div>
                  <div
                    className={`p-8 bg-white shadow-lg mx-4 -mt-8 z-10 rounded-sm flex flex-col text-sm xl:text-base flex-1`}
                  >
                    <Link
                      to={`/tags/${kebabCase(post.frontmatter.mainTag)}`}
                      className="font-poppins pb-2 text-lighttext hover:text-primary"
                    >
                      {post.frontmatter.mainTag}
                    </Link>
                    <h3 className="font-poppins text-xl xl:text-2xl font-semibold mb-4">
                      <Link
                        to={post.fields.slug}
                        className="hover:text-primary"
                      >
                        {post.frontmatter.title}
                      </Link>
                    </h3>
                    <p className="font-poppins pb-4 text-lighttext">
                      {post.frontmatter.description}
                    </p>
                    <div className="mt-auto flex justify-start items-center">
                      <p className="font-poppins font-light italic text-lighttext">
                        {post.frontmatter.date}
                      </p>
                      <Link
                        to={post.fields.slug}
                        className="ml-auto text-primary hover:text-secondary"
                      >
                        Read More <FontAwesomeIcon icon={faArrowRight} />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const post = data.postData;
  const { siteUrl } = data.site.siteMetadata;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta property="og:type" content="article" />
            <meta
              property="og:description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:image"
              content={`${
                siteUrl +
                data.ogImage.frontmatter.featuredimage.childImageSharp.fixed.src
              }`}
            />
            <meta property="og:url" content={`${siteUrl + post.fields.slug}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:image:alt"
              content={`Featured Image for ${post.frontmatter.title}`}
            />
          </Helmet>
        }
        mainTag={post.frontmatter.mainTag}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        contentComponent={HTMLContent}
        author={`Mysti Keglovits`}
        disclaimer={post.frontmatter.disclaimer}
        featuredimage={post.frontmatter.featuredimage}
        relatedPosts={data.relatedPosts.edges}
        previousPost={data.previousPost}
        nextPost={data.nextPost}
        tableOfContents={post.tableOfContents}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID(
    $id: String!
    $relatedPosts: [String!]!
    $previousPost: String
    $nextPost: String
  ) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    postData: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        mainTag
        disclaimer
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 2000)
          }
        }
      }
      fields {
        slug
      }
      tableOfContents
    }
    ogImage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        featuredimage {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: { frontmatter: { title: { in: $relatedPosts } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            mainTag
            description
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    nextPost: markdownRemark(id: { eq: $nextPost }) {
      id
      frontmatter {
        title
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
      fields {
        slug
      }
    }
    previousPost: markdownRemark(id: { eq: $previousPost }) {
      id
      frontmatter {
        title
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
